<script setup lang="ts">
import { FontAwesomeIcon } from '@fortawesome/vue-fontawesome'
import { faInfoCircle } from '@fortawesome/free-solid-svg-icons'
// import TheIcon from '../TheIcon.vue'

type Props = {
    enteredEmail: string;
    agreeWithPolicyAndConditions: boolean
    isSubmitted: boolean
    emailAuthorizationError?: string
    isDisabled?: boolean
}

const props = defineProps<Props>()

const emit = defineEmits(['submitModalSignUpForm', 'updateEnteredEmail', 'updateAgreeWithPolicyAndConditions'])

</script>

<template>
    <form @submit.prevent="emit('submitModalSignUpForm')">
        <div v-if="isSubmitted" class="row mb-3">
            <div class="col-12">
                <div class="alert alert-success mb-0">
                    <p class="mb-3 text-break">
                        We've sent an activation link to "{{ enteredEmail }}"
                    </p>
                    <!-- <TheIcon
                        icon-id="check-icon-animated"
                        width="50"
                        height="50"
                    /> -->
                                 
                    <svg class="checkicon" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 52 52"> 
                        <circle
                            class="checkicon__circle"
                            cx="26"
                            cy="26"
                            r="25"
                            fill="none"
                        /> 
                        <path class="checkicon__check" fill="none" d="M14.1 27.2l7.1 7.2 16.7-16.8" />
                    </svg>
                    <p class="mb-3">
                        <b>Important:</b> Be sure to check your "<em>spam</em>" folder, too. Please mark our email as safe to ensure you receive our updates in your inbox!
                    </p>
                    <p class="mb-3">
                        If you still haven't received it after 15 minutes, please return to this page to try sending the email again.
                    </p>
                </div>
            </div>
        </div>
        
        <div v-if="!isSubmitted">
            <div v-if="emailAuthorizationError" class="alert alert-danger">
                {{ emailAuthorizationError }}
              
                <!-- <TheIcon
                    icon-id="denied-icon-animated"
                    width="50"
                    height="50"
                /> -->
                <svg 
                    id="denied-icon-animated"
                    class="denied-icon" 
                    xmlns="http://www.w3.org/2000/svg" 
                    viewBox="0 0 52 52"
                >
                    <circle
                        class="denied-circle"
                        cx="26"
                        cy="26"
                        r="25"
                        fill="none"
                    />
                    <path class="denied-x" fill="none" d="M16 16, 36 36M36 16, 16 36" />
                </svg>
            </div>
            <div class="form-group mb-5">
                <label for="email">
                    Your best Email Address 
                    <span
                        data-toggle="tooltip"
                        class="one-field-sign-up-form-tooltip"
                        data-placement="top"
                        title="We recommend using the email you book with on Hostelworld & Booking.com"
                    >
                         
                        <FontAwesomeIcon :icon="faInfoCircle" />

                    </span>
                </label>
                 
                <input
                    id="email"
                    :value="enteredEmail"
                    class="form-control"
                    type="email"
                    name="email"
                    placeholder="What is your best email?"
                    autofocus
                    required
                    @input="emit('updateEnteredEmail', $event)"
                >
            </div>
            <div class="form-group mb-4">
                <div class="custom-control custom-checkbox">
                    <input
                        id="flexSwitchCheckDefault"
                        :checked="agreeWithPolicyAndConditions"
                        type="checkbox"
                        class="custom-control-input"
                        name="tc"
                        required
                        @change="emit('updateAgreeWithPolicyAndConditions', $event)"
                    >
                    <label for="flexSwitchCheckDefault" class="custom-control-label"> 
                        <span class="text-sm mr-6 mr-md-5 cl-text">By creating an account, you agree with our 
                            <a
                                href="/terms-conditions"
                                target="_blank"
                                class="cl-link"
                            >Terms & conditions</a> and <a
                                href="/privacy"
                                target="_blank"
                                class="cl-link"
                            >Privacy policy</a>
                        </span>
                    </label>
                </div>
            </div>

            <button
                class="btn btn-lg btn-block btn-primary"
                type="submit"
                name="submit"
                :disabled="isDisabled"
            >
                Sign Up
            </button>
        </div>
    </form>
</template>

<style scoped lang="scss">

.custom-control-input {
    right: 9px;
    top: 50%;
}
</style>